
<template>
  <ValidationObserver
    ref="userForm"
    v-slot="{ handleSubmit, failed}"
  >
    <b-toast
      :visible="failed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group
        label="รหัสสมาชิก"
        label-cols-lg="3"
        label-cols-sm="12"
        label-for="codeName"
      >
        <b-form-input
          id="codeName"
          v-model="playerData.codeName"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="`${$t('user.username')} `"
        label-cols-lg="3"
        label-cols-sm="12"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="playerData.username"
          :readonly="!$allowPermission('agent:edit.username-and-bank-account')"
          placeholder="@username"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('user.link_af')"
        label-cols-lg="3"
        label-cols-sm="12"
        label-for="affiliateLink"
      >
        <b-form-input
          id="affiliateLink"
          v-model="playerData.affiliateLink"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('fields.remark')"
        label-cols-lg="3"
        label-cols-sm="12"
        label-for="remark"
      >
        <b-form-input
          id="remark"
          v-model="playerData.note"
        ></b-form-input>
      </b-form-group>
      <div class="text-right">
        <b-button
          class="mr-2"
          variant="light"
          @click="setData"
        >
          {{ $t('buttons.cancel')}}
        </b-button>
        <b-overlay
          :show="player.isUpdatingPlayer"
          class="d-inline-block"
          rounded
          spinner-small
        >
          <b-button
            block
            type="submit"
            variant="primary"
          >
            {{ $t('buttons.save')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  data() {
    return {
      playerData: {
        affiliateLink: '',
        codeName: '',
        note: '',
        username: '',
        phoneNumber: '',
      },
    }
  },
  computed: {
    ...mapState(['player']),
    ...mapGetters(['playerDetail']),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    playerProfile() {
      return this.playerDetail.profile
    }
  },
  watch: {
    playerProfile(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      if (this.playerProfile && this.playerProfile.username) {
        const data = JSON.parse(JSON.stringify(this.playerProfile))
        this.playerData = { ...data }
      } else {
        this.playerData = {
          affiliateLink: '',
          codeName: '',
          note: '',
          username: '',
          phoneNumber: '',
        }
      }
    },
    onSubmit() {
      this.updatePlayer({ playerId: this.playerId, data: this.playerData})
    },
  },
}
</script>
